.information-page__wrapper{
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.main-info__wrapper{
    position: fixed;
    top: 0;
    left: 0;
}

.main-info__wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-options__wrapper{
    width: 90%;
    height: 75%;
    color: var(--color-highlighted);
    scroll-snap-type: y mandatory;
}

.active-content__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.info-option{
    text-align: center;
}

.info-active{
    transform: translate(0px, -200px);
}

.info-header{
    position: relative;
    will-change: transform;
    transition: transform 0.85s cubic-bezier(0.22, 1, 0.36, 1), color 0.75s cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.25rem;
    font-family: var(--font-punchy);
}

.info-title{
    display: inline-block;
    font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 9.5px));
}
.title-decorator{
    position: absolute;
    opacity: 0;
    animation: fadeIn 0.45s ease-in forwards;
    animation-delay: 0.35s;
}

.active-opt{
    color: white;
}

#kit-info__wrapper{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: repeat(5fr, 1fr);
}

.kit-item{
    position: relative;
    /* counter-increment: item; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 9.5px));
    font-family: var(--font-punchy);
    text-align: center;
    align-content: center;
    width: 100%;
    display: inline-block;
}

.kit-col__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90vw;
    padding-top: 25px;
}
.kit-header__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 90vw;
}

.bio-content__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: var(--font-1);
    padding-top: 20px;
}
.bio__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bio-para__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;   
}
.overflow-wrap{
    overflow: hidden;
}
#bio-accent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 9.5px));
    font-weight: bold;
}

.bio-para{
    width: 90%;
    font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 9.5px));
    font-weight: bold;
    color: rgb(183, 183, 183);
}

.kit-grid__wrap{
    display: flex;
    flex-direction: column;
}
.active-info{
    position: absolute;
    height: calc(60% - 50px);
    width: 90vw;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    letter-spacing: 2px;
    overflow: hidden;
}

.active-info__wrapper{

}

.kit-col, .kit-header__wrapper{
    flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.kit-col{
    color: var(--color-highlighted);
}

.kit-col__header{
    color: white;
    width: calc(90vw / 4);
    font-size: calc(max((50 / var(--headerFontMultiplier))*1rem, 9.5px));
}
.web-credits{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-highlighted);
    text-transform: uppercase;
    font-family: var(--font-punchy);
    font-size: var(--font-0);
}

.web-credits>span{
    padding: 15px;
}

.contact-wrap{
    font-size: calc(max((50 / var(--headerFontMultiplier))*1rem, 9.5px));
}