.info-page__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display:flex;
    min-height: 100%;
    color: rgb(251, 251, 251);
    font-size: calc(max((25 / var(--headerFontMultiplier))*0.9rem, 9px));
    font-weight: 300;
    font-family: var(--font-helv)
}

.info-content__wrapper{
    padding-top: 10vh;
    position: relative;
    z-index: 4;
}
.info-tag-styler{
    margin-right: 0.15rem;
}

.info-bg__wrapper, .info-content__wrapper{
    width: 100%;
    padding-bottom: 15vh;
    will-change: transform;
}

.fw-700{
    font-weight: 700;
}

.italic{
    font-style: italic;
}

.info-header__wrapper{
    padding-left: 1rem;
    width: calc((100% / 13) * 8);
    font-size: 6rem;
    font-weight: 500;
}

.stretch{
    transform: scaleX(1.1);
}

.info-header__wrapper > div{
    line-height: 0.95;
}

.ls-sm{
    letter-spacing: 0.65rem;
}


.header-row-2{
    text-align: center;
}

.header-row-3{
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: space-between;
}

.info__upper-tile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.work-details-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    font-weight: 700;
    padding-bottom: 1rem;
}

.pg-sp-2{
    padding-top: 14vh;
}

.pg-sp-1{
    padding-top: 7vh;
}

.page-splitter{
    width: 100%;
    display: flex;
    flex-direction: row;
}


.sub-sec-value, .sub-sec-varname, .info-sec__footer{
    line-height: 1;
    white-space: nowrap;
}

.mg-tp-xs{
    margin-top: 0.25rem;
}


.mg-tp-sm{
    margin-top: 0.5rem;
}

.mg-tp-md{
    margin-top: 1rem;
}

.info__sub-sec{
    display: flex;
    flex-direction: row;
    display: inline-block;
    align-items: space-between;
}

.info__left-col .info-pg-section{
    padding-bottom: 8vh;
}

.info__right-col .info-pg-section{
    padding-bottom: 4vh;
}



.info-pg-section{
    line-height: 2;
    
}

.info-sec__header{
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 2vh;
}

.info-sec__header, .info__var-col{
    font-weight: 600;
}

.info__val-col{
    display: flex;
    flex-direction: row;
}

.info__val-col-1, .info__val-col-2{
    display: flex;
    flex-direction: column;
}
.info-sec__table{
    display: flex;
    flex-direction: row;
}

.info__left-col .info-sec__table > div:first-child, .info-gear-cols > div:first-child{
    padding-right: 3rem;
    white-space: nowrap;
}

.info-sec__wrapper .info__val-col-1{
    padding-right: 1rem;
}

.info__left-col{
    width: 50%;
}

.info__left-col, .info__right-col{
    display:flex;
    flex-direction: column;
}

.info__right-col{
    width: 50%;
    align-items: flex-end;
}

.info__right-col .sub-sec-value{
    text-transform: capitalize;
}
.info__right-col *{
    text-align: right;
}
.pg-sp-2 .info__val-col-1 .sub-sec-value {
    font-weight: 200;
    text-transform: lowercase;
}

/* BACKGROUND ELEMENTS */
.info-bg__wrapper{
    padding-top: 15vh;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    z-index: 5;
    mix-blend-mode: difference;
    pointer-events: none !important;
}

.bg-figure:nth-child(2),.bg-figure:nth-child(3),.bg-figure:nth-child(4) {
    height: 6rem
}

.bg-figure:nth-child(1) {
    grid-row: 1
}

.bg-figure:nth-child(2) {
    grid-row: 2
}

.bg-figure:nth-child(3) {
    grid-row: 3
}

.bg-figure:nth-child(4) {
    grid-row: 4
}

.bg-figure:nth-child(5) {
    grid-row: 5
}



.bg-figure:nth-child(1) {
    grid-column: 10/span 2;
    height: 300px;
    margin-top: 4rem;
}
.bg-figure:nth-child(2) {
    grid-column: 3/span 1;
    height: 200px;
    margin-top: -15rem;
}

.bg-figure:nth-child(3) {
    grid-column: 7/span 1;
    height: 210px;
    margin-top: -15rem;
}

.bg-figure:nth-child(4) {
    grid-column: 5/span 1;
    height: 200px;
    margin-top: -15rem;
}

.bg-figure:nth-child(5) {
    grid-column: 11/span 1;
    margin-top: -15rem;
    height: 7rem;
}


.bg-figure{
    width: 100%;
    overflow: hidden;
    filter: blur(2px);
    opacity: 0;
}

.info-cont-header{
    font-weight: 600;
}

.info-about{
    width: 33%;
}

.ov-hid{
    overflow: hidden;
}

.info-header__wrapper span{
    display: inline-block;
    margin-left: 0.65rem;
}

.info-header__wrapper .header-row{
    overflow: hidden;
}

/* Footer */
.information-page__footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-family: var(--font-helv);
    font-weight: 400;
}

.social-icons__container{
    position: absolute; 
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rights-box{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-transform: uppercase;
}

/* View projects wrapper */
.info__page-ender{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pre-ender{
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 6rem;
    text-align: center;
    white-space: nowrap;
    font-size: 1.2rem;
    letter-spacing: 1.2rem;
    font-weight: 800;
    text-align: center;
}

#more-projects__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: max(30vw, min(400px, 100%));
}

.more-projects__title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 200;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 6px;
    transition: transform 0.5s ease;
}

.image__footer-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    font-size: max(0.6rem, 9px);
    font-family: var(--font-punchy);
    text-transform: uppercase;

}

.image-control__wrapper{
    width: 100%;
    aspect-ratio: 2/1;
    overflow: hidden;
    position: relative;
}

#underline-ele{
    position:absolute;
    width: 0%;
    height: 1px;
    background: white;
    border-radius: 3px;
    float: right;
    transition: width 0.5s ease;
    top: -4px;
}

.full-width{
    width: 100%;
}

.underline__wrapper{
    position: relative;
    height: 1px;
}

.vimeo-icon{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.info-gear-cols{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.rights-box a:not(:last-child){
    margin-right: 1rem;
}


.contact-item span:hover, .rights-box a:hover{
    color: var(--kodak-bright);
    transition: color 0.4s ease;
}

.header-row-1, .header-row-3{
    display: flex;
    flex-direction: row;
}

@media (max-width: 860px){
    .info-about {
        width: 100%;
    }
    .pre-ender{
        margin-top: 14rem;
        margin-bottom: 10rem;
    }
    .pg-sp-1 {
        padding-top: 3rem;
    }
    .pg-sp-2 {
        padding-top: 6rem;
    }
    .info__left-col .info-pg-section {
        padding-bottom: 3rem;   
    }
    .information-page__footer{
        margin-bottom: 1.5rem;
    }

    .rights-box a:not(:last-child){
        margin-right: 2rem;
    }
}

@media (max-width: 700px){
    .info__left-col .info-sec__table > div:first-child, .info-gear-cols > div:first-child{
        padding-right: 7rem;
    }

    

    .info__upper-tile{
        flex-direction: column;
    }

    .info__val-col{
        padding-right: 2rem;
        justify-content: space-between;
    }

    .info__val-col-1 .sub-sec-value {
        font-weight: 300;
        padding-right: 3rem;
    }

    .info__right-col{
        justify-content: flex-end;
        width: unset;
    }
    .header-row-2{
        text-align: left;
    }

    .info-header__wrapper{
        padding-left: 0rem;
        font-size: 6rem;
        width: auto;
    }

    .pre-ender{
        margin-top: 8rem;
        margin-bottom: 8rem;
    }

    .info-content__wrapper {
        padding-top: 10rem;
    }

    .info-sec__wrapper{
        font-size: 1rem;
        width: 100%;
    }

    .pg-sp-1{
        padding-top: 4rem;
    }
    .pg-sp-1 .info__left-col{
        width: 100%;
    }
    .pg-sp-1 .info__right-col{
        justify-content: flex-start;
    }
    .pg-sp-2 .info__left-col{
        width: 100%;
    }

    #second-right-col{
        justify-content: space-between;
        font-size: 1rem;
        margin-top: 20px;
    }

    .contact-details__wrapper{
        font-size: 1.25rem;
    }

    .info-about {
        font-size: 1.25rem;
    }

    .info-cont-header, .sub-sec-varname{
        font-size: 1.25rem;
    }

    .work-details-wrapper{
        font-size: 1rem;
        padding-bottom: 0.25rem;
        margin-top: 2rem;
    }

    .work-details-wrapper span{
        white-space: nowrap;
    }

    .info__left-col .info-pg-section{
        padding-bottom: 0rem;
    }

    .info-location__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 2rem;
        max-width: 175px;
        width: 100%;
        box-sizing: content-box;
        font-size: 1.25rem;
        white-space: normal;
    }

    .pg-sp-2{
        padding-top: 4rem;
    }

    .info-about{
        width: 100%;
        padding-right: 2rem;
    }
    .more-projects__title {
        font-weight: 500;
        padding: 0 1rem;
    }
    .pre-ender{
        letter-spacing: 0.9rem;
        font-weight: 600;
    }
    .info-bg__wrapper, .info-content__wrapper{
        padding-bottom: 10vh;
    }
}

@media(max-width: 400px){
    .info__left-col .info-sec__table > div:first-child, .info-gear-cols > div:first-child {
        padding-right: 4rem;
    }

    .sub-sec-value{
        padding-right: 1.5rem;
    }

    .info-about{
        padding-right: 1rem;
    }

    .info-about, .contact-details__wrapper, .info-location__wrapper {
        font-size: 1rem;
    }
}  