
/* svg {
    width: 100vw;
    height: 100vh;
  }
  */

  p {
    position: absolute;
    font-size: 1.125rem;
    font-weight: 500;
    bottom: 1rem;
    right: 1rem;
    color: #fff;
    font-family: system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  #hero-vid{
    position: absolute;
    fill: white;
  }

  /* svg * {
    transform-box: fill-box;
    transform-origin: center;
    fill: #020202;
    fill-opacity: 0.95;
    stroke-opacity: 0;
  }  */

svg > rect {
    -webkit-mask: url(#hero-clipper);
    mask: url(#hero-clipper);
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}