.shoot-page__wrapper{
}

.main-landing__wrapper{

}

.shoot-image, .shoot-gradient{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: all;
}

.shoot-image{
    z-index: 1;
    left: 50%;
    top: 50%;
    aspect-ratio: 1.5;
}

.shoot-gradient{
    width: 100vw;
    height: 100lvh;
    background-image: linear-gradient(rgba(0,0,0,0) 50%, calc(100% - 100px), rgba(0, 0, 0,1) calc(100% - 35px));
    z-index: 2;
    pointer-events: none;
}

.shoot-footer{
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left: 0;
    bottom: 0;
    color: var(--kodak-dark);
    z-index: 3;
    margin-bottom: max(1rem, 30px);
    font-size: calc(max((22 / var(--headerFontMultiplier))*1rem, 9.25px));
    font-weight: 600;
    overflow: hidden;
}

.shoot-hero{
    position: fixed;
    opacity: 1;
    will-change: transform;
}

.shoot-info, .shoot-num{    
    font-weight: 300;
    color:#dddddd;
}

.shoot-video{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100vw;
    transform-origin: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
    pointer-events: none;
    transition: filter 0.85s ease-out;
    transform: translate3d(0, -50%, 0) scale(1,1);
}

.st-num{
    color: white;
    opacity: 0.8;
    font-weight: 400;
}

.shoot-video video{
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    object-fit: contain;
    min-width: 100%;
    width: auto !important;
    height: auto !important;
    min-height: 100%;
    z-index: 0;
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
}

.shoot-video > div{
    pointer-events: all;
}

.shoot-num{
    padding-right: 1rem;
}
.shoot-num, .shoot-title{
    display: inline-block;
}

.hide{
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
}

.shoot-content__wrapper{
    will-change: transform;
    position: fixed;
    top: 0;
    left: 0;    
    width: 100%;
    opacity: 1;
    z-index: 5;
}

.shoot-content{
    height: 100%;
    width: 100%;
    position: relative;
    /* margin-top: 120vh; */
    /* background-image: linear-gradient(rgba(179, 50, 43, 0), 10%, rgba(0, 0, 0,1)); */
}
/* INFO CONTENT */
.info-section__wrapper{
    font-size: calc(max((24 / var(--headerFontMultiplier))*1rem, 9.25px));
    font-weight: 400;
    color: #f4f4f4
}

.info-section:first-child{
    margin-bottom: 5rem;
}


.aux-vert{
    display:flex !important;
    flex-direction: column !important;
    justify-content: start !important;
}

.aux-section{
    margin: 0 20vw;
}

.credit-section, .aux-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.info-col-1{
    margin-right: 3rem;
    text-align: left;
}

.info-col-2{
    font-weight: 600;
    text-transform: uppercase;
}

.info-section > div > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-col-1, .info-col-2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.shoot-info-header{
    color: var(--color-highlighted)
}


.left-col{
    text-align: left;
}

.center-col{
    text-align: middle;
}

.right-col{
    text-align: right;
}

.stills__wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;   
    padding-bottom: 20vh;
}

.next-prev-content__wrapper{
    display: flex;
    flex-direction: column;
    width: 35%;
    position: relative;
    justify-content: space-between;
    color: #c5c5c5;
}

.next-prev-content__wrapper > .shoot-tile__wrapper{
    aspect-ratio: 1.75;
    width: 100%;
}

.next-option{
    text-align: right;
}

.prev-next-title__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stills__wrapper div:nth-child(3n) {
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.shoot-section-wrapper{
    padding: 4rem 0;
    padding-top: 4rem;
    padding-bottom: 0.5rem;
}

.stills__wrapper > div > img{
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.video-overlay{
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 3;
    pointer-events: all;
}

.playback-features{
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    padding-bottom: 50px;
}

.playback-details{
    width: 100vw;
    pointer-events: all;
}

.playback-times{
    padding-top: 10px;
    font-size: max(var(--font-0), 11px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:top;
}

.vimeo__wrapper > div{
    max-height: 100%;
    padding: 0 !important;
    height: 100%;
}

.sound-motion-mob{
    position: relative;  
}

.sound-option-mob{
    padding: 0.5rem 2px;
}

.sound-strikethrough{
    position: absolute;
    height: 1px;
    top: 50%;
    background: white;
    transition: width 0.75s cubic-bezier(0.22, 1, 0.36, 1);
}

.sound-option{
    transform: rotate(-90deg);
    position: fixed;
    top: 50%;
    right: 0;
    pointer-events: all;
    z-index: 100;
    cursor: pointer;
    margin: 1rem;
    padding: 0.5rem 10px;
}


.footer-wrapper{
    height: 100dvh;
    width: 100vw;
    position: relative;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 10;
}
.info-item-wrap{
    padding: 3px 0;
    transform: scaleY(0.95);
    font-weight: 600;
}


.info-item-wrap > span{
    font-weight: 300;
    color: #f4f4f4;
}

#long-lat{
    font-weight: 600;
}

#long-lat, .info-item-wrap{
    color: var(--kodak-dark);
}

.playback-option-dial{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    height: 1.325rem;
    width: 1.325rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid white;
}

.playback-title{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    font-size: calc(max(23.5px, 1.1rem))
}

.playback-title > span{
    white-space: pre-wrap;
    max-width: calc(100% - 1.325rem);
    text-wrap: balance;
}

.close-option{
    text-decoration: underline;
}

.mobile-control__wrapper span{
    pointer-events: all;
}

.pause-icon{
   height: calc((11 / 20) * 0.65rem);
   width: calc((6 / 20) * 0.65rem); 
}

.play-icon, .pause-icon{
    overflow: visible;
}

.play-icon{
    height: calc((11 / 20) * 0.65rem);
    width: calc((9 / 20) * 0.65rem); 
    margin-left:3px;
}

.still-item{
    position: relative;
}


.still__wrapper{
    height: 100%;
    width: 100%;
}

.shoot-tile__wrapper{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shoot-tile__wrapper * {
    pointer-events: none;
}

.shoot-content__grad{
    background: linear-gradient(to top,#080808,rgba(8,8,8,0) 100%);
    height: 50lvh;
    position:relative;
    left: 0;
    bottom: -3px;
    width: 100vw;

}

.mobile-control__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    margin-top: 25px;
    font-size: calc(max((22 / var(--headerFontMultiplier))*1rem, 12px)); 
}

.s-content-wrap{
    background: #080808;
}

.left-col, .right-col, .center-col{
    display: flex;
    flex-direction: row;
}


.shoot-section-header{
    margin-bottom: 2rem;
    font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 10.5px));
}

.shoot-section-header > span{
    padding-bottom: 1px;
}

.content-scroll{
    will-change: transform;
}

.next-prev-section{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    font-family: var(--font-helv);
    font-size: calc(max((28 / var(--headerFontMultiplier))*1rem, 9.5px));
    color: white;
    justify-content: space-between;
    pointer-events: all !important;
}

.prev-next__bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
}

.paused-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 8rem;
    align-items: center;
    pointer-events: none;
    mix-blend-mode: difference;
    z-index: 10;
    color:white;
}
.shoot-fullscreen{
    color:#dddddd;
    font-weight: 300;
}


.prev-next-option{
    margin-bottom: 6px;
    font-weight: 400;
}

.prev-next-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    text-transform: uppercase;
    padding-top: 10px;
    font-weight: 600;
}
#st-l, #st-f{
    /* font-weight: 500; */
    color: var(--kodak-dark);
}


@media screen and (min-width: 1181px) {

    .next-prev-section{
        font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 9.5px));
    }

    .stills__wrapper{
        padding-bottom: 60px;
    }
    .next-prev-section{
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 1180px) and (max-height: 1180px) and (min-width: 860px) {
    .next-prev-content__wrapper{
        width: 35%;
    }
    .stills__wrapper{
        padding-bottom: 60px;
    }
    .next-prev-section{
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 1180px) and (min-height: 1180px) and (min-width: 860px){
    .next-prev-content__wrapper{
        width: 40%;
    }
    .stills__wrapper{
        padding-bottom: 60px;
    }
    .next-prev-section{
        padding-bottom: 40px;
    }
}

@media (max-width: 860px){
    .info-col-1{
        margin-right: 1.5rem;
        text-align: left;
    }
    .playback-features{
        padding-bottom: 12.5vh;
    }
    .playback-option-dial{
        height: 2rem;
        width: 2rem;
    }
    .playback-title > span{
        max-width: calc(100% - 2rem);
    }
    .play-icon, .pause-icon{
        height: 0.5rem;
        width: 0.5rem;
    }
    .playback-title{
        font-size: calc(max((55 / var(--headerFontMultiplier))*1rem, 21.5px));
        width: 100%;
        justify-content: space-between;
        margin-bottom: max(8px, calc(1rem/1.5));
    }
    .playback-times{
        font-size: calc(max((21 / var(--headerFontMultiplier))*1rem, 10.5px));
        padding-top: 5px;
    }
    .shoot-footer{
        margin-bottom: 1.5rem;
    }
    .stills__wrapper{
        padding-bottom: 60px;
    }
    .next-prev-section{
        padding-bottom: 40px;
    }
    .prev-next-title{
        padding-top: 6px;
    }
}

@media (max-width: 700px){
    .playback-title{
        font-size: 3.25rem;
        
    }
    .playback-title > span{
        max-width: calc(100% - 3rem);
    }
    .playback-option-dial{
        height: 3rem;
        width: 3rem;
    }
    .play-icon, .pause-icon{
        height: 0.75rem;
        width: 0.75rem;
    }
    .shoot-section-header{
        padding-top: 3rem;
        font-size: calc(max((35 / var(--headerFontMultiplier))*1rem, 10.5px));
    }

    .sh-2{
        padding-top: 0rem;
    }

    .as-2{
        padding-bottom: 2rem;
    }

    .info-section{
        margin-bottom: 5rem;
        font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 9.5px));
    }

    .shoot-content__grad{
        height: 20vh;
    }
    .stills__wrapper{
        grid-template-columns: repeat(1,1fr);
        padding-bottom: 40px;
    }
    .next-prev-section{
        padding-bottom: 30px;
    }
    .stills__wrapper div:nth-child(3n) {
        grid-column-end: span 1;
        grid-row-end: span 1;
    }
    .info-col-1, .info-col-2{
        margin-right: 1.5rem;
        text-align: left;
        width: 50%;
        display: flex;
        flex-direction: column;
        
    }


    
    .info-section__wrapper span{
        margin: 1.5px 0;
    }


    .aux-section{
        margin: 0;
        flex-direction: column;
        align-items: start;
    }

    .aux-vert:first-child{
        margin-bottom: 15px;
    }
    .next-prev-section{
        flex-direction: column;
    }.next-prev-content__wrapper{
        width: 85%;
        margin-bottom: 15px;
    }
    .next-prev-content__wrapper:nth-child(2n){
        right: 0;
        left: 15%;
    }
    .shoot-info{
        left:auto;
        right: 1.5rem;
    }
}


.relative-del{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

.shoot-click-signifier{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);
    mix-blend-mode: difference;
}