:root {
    --scenePerspective: 1.15;
    --scenePerspectiveOriginX: 50;
    --scenePerspectiveOriginY: 50;
    --itemZ: 1.5;
    --cameraSpeed: 550;
    --cameraZ: 0;
    --viewportHeight: 0;
  }
  
.viewport {
  height: calc(var(--viewportHeight) * 1px);
}

.viewport .scene3D-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-perspective: calc(var(--scenePerspective) * var(--cameraSpeed) * 1px);
          perspective: calc(var(--scenePerspective) * var(--cameraSpeed) * 1px);
  -webkit-perspective-origin: calc(var(--scenePerspectiveOriginX) * 1%) calc(var(--scenePerspectiveOriginY) * 1%);
          perspective-origin: calc(var(--scenePerspectiveOriginX) * 1%) calc(var(--scenePerspectiveOriginY) * 1%);
  will-change: perspective-origin;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.scene-anim-in{
  transform: translate3d(0,0,0px) !important; 
  opacity:1 !important;  
}

.scene-anim-out{
  transform: translate3d(0,0,3000px) !important; 
}

.viewport .scene3D-container .scene3D {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-style: preserve-3d !important;
          transform-style: preserve-3d !important;
  transition: transform 0.75s ease;
  /* transform: translate3d(0,0,-3000px);    */
}

.viewport .scene3D-container .scene3D > div {
  position: absolute;
  display: block;
  width: 45%;
  /* filter: blur(calc(10 - (1000 / (var(--cameraZ) - (var(--itemZ) * var(--cameraSpeed)))))px); */

}

.viewport .scene3D-container .scene3D > div > div {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  background-color: #020202;
  color: #272935;
  padding: 0;
  margin: 0;
}

h1 {
  font-family: "Playfair Display SC", serif;
  font-size: 1.5rem;
  color: white;
  margin: 0;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  text-align: center;
}

h1 .logo {
  display: block;
  margin: 0 auto;
  max-width: 150px;
}

.ddd-wrapper{
    opacity: 0;
    will-change: transform, filter, opacity;
}

.ddd-in-view{
    
}

.link {
  color: white;
  margin: 0;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

/* .scene3D > div {
    width: 300px;
    height: 200px;
} */

.scene3D > div h2 {
  margin-top: 0;
  font-family: "Playfair Display SC", serif;
  font-size: 1.5rem;
}
/* 
.scene3D > div:nth-child(0) {
  background-color: #ffc2e0;
}

.scene3D > div:nth-child(1) {
  background-color: #ffc2c2;
}

.scene3D > div:nth-child(2) {
  background-color: #ffe0c2;
}

.scene3D > div:nth-child(3) {
  background-color: #ffffc2;
}

.scene3D > div:nth-child(4) {
  background-color: #e0ffc2;
}

.scene3D > div:nth-child(5) {
  background-color: #c2ffc2;
}

.scene3D > div:nth-child(6) {
  background-color: #c2ffe0;
}

.scene3D > div:nth-child(7) {
  background-color: #c2ffff;
}

.scene3D > div:nth-child(8) {
  background-color: #c2e0ff;
}

.scene3D > div:nth-child(9) {
  background-color: #c2c2ff;
}

.scene3D > div:nth-child(10) {
  background-color: #e0c2ff;
}

.scene3D > div:nth-child(11) {
  background-color: #ffc2ff;
}

.scene3D > div:nth-child(12) {
  background-color: #ffc2e0;
}

.scene3D > div:nth-child(13) {
  background-color: #ffc2c2;
}

.scene3D > div:nth-child(14) {
  background-color: #ffe0c2;
}

.scene3D > div:nth-child(15) {
  background-color: #ffffc2;
}

.scene3D > div:nth-child(16) {
  background-color: #e0ffc2;
}

.scene3D > div:nth-child(17) {
  background-color: #c2ffc2;
}

.scene3D > div:nth-child(18) {
  background-color: #c2ffe0;
}

.scene3D > div:nth-child(19) {
  background-color: #c2ffff;
}

.scene3D > div:nth-child(20) {
  background-color: #c2e0ff;
}

.scene3D > div:nth-child(21) {
  background-color: #c2c2ff;
}

.scene3D > div:nth-child(22) {
  background-color: #e0c2ff;
}

.scene3D > div:nth-child(23) {
  background-color: #ffc2ff;
}

.scene3D > div:nth-child(24) {
  background-color: #ffc2e0;
}

.scene3D > div:nth-child(25) {
  background-color: #ffc2c2;
} */
/*# sourceMappingURL=style.css.map */



.z-title__wrapper{
    width: 100%;
    height: 100%;
   
    font-size: var(--font-3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
    position: relative;
}



.z-thumb{
    max-width: 100%;
    word-wrap: break-word;
    text-transform: uppercase;
    text-align: center;
    pointer-events: none;
}


.z-title__wrapper > span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: var(--font-clean);
  font-weight: 300;
  font-size: 2.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.z-title__wrapper > span:first-child{
  mix-blend-mode: difference;
}

.z-title__wrapper > span:last-child{
  color: #eaeaea;
}

.z-title__wrapper:hover > span:first-child{
  opacity: 1;
}

.z-title__wrapper:hover > span:last-child{
  opacity: 0.3;
}