.hero{
    max-height: 100%;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    position: relative;
}
.hero::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(rgba(0,0,0,0), 50%, rgba(0,0,0,0.6));
    pointer-events: none;
  }

.homepage-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.homepage-bottom-fixed{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: max(1rem, 30px);
    color: white;
    z-index: 10;
    font-family: var(--font-helv);
}


.homepage-wrapper::-webkit-scrollbar {
    width: 1px;
  }

.hero > video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* -webkit-clip-path: url(#hero-clipper);
    clip-path: url(#hero-clipper); */
    /* mask: url(#hero-clipper);
    -webkit-mask: url(#hero-clipper); */
}

.wrapper:before, .wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    width: 10px;
    height: 100%;
    background-color: #fff;
}

svg > rect {
    -webkit-mask: url(#hero-clipper);
    mask: url(#hero-clipper);
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
#hero-svg{
    fill-opacity: 0.33;
    mix-blend-mode: darken;
    filter: blur(5px) drop-shadow(0px 0px 20px black);
    padding: 100px;
}

#hero-svg{
    fill-opacity: 0.16;
}

.blank-roll:first-child{
    /* background-image: url("../../../public/Media/Film/filmburn5.png"); */
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 305%;
    height: 130%;
    margin-top: calc(20px + .78125vw);
    left: -30px;
    z-index: 1000;
    mix-blend-mode: lighten; */
}

/* svg {
    width: 100%;
    height: 100%;
    filter:invert(1);
    -webkit-filter: invert(1);
} */
svg rect {
    fill: white;
}

.hero > .player-wrapper{
    height: 100%;
    width: 100%;
}

.heroThumb{
    width: 100%;
    height: 100%;
}

.hero > .player-wrapper > .react-player > video{
    width: auto !important;
    min-width: 100vw !important;
    height: auto !important;
    min-height: 100vh;
}

.hero > .player-wrapper > .react-player{
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: auto !important;
}

.hero-cover{
    height: 100%;
    width: 100%;
    background: #020202;
    opacity: 0.5;
}

.difference{
    position:absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 150px;
    height: 60px;
    color: #020202;
    mix-blend-mode: difference;
    font-family: lato;
    font-size: 24px;
    border-radius: 5px;
}

/* PROJECT SECTION CSS */
.project-section-wrapper, .shoot-section-wrapper{
    padding-top: 7.5rem;
    padding-bottom: 3rem;
    /* padding-right: 9rem;
    padding-left: 9rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-section-header{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    overflow-x:hidden;
}

.project-section-subtitle{
    font-size: var(--title-5);
    font-weight: 100;
    
}

.project-section-subtitle > span, .project-section-title > div > span{
    display: inline-block;
    will-change: transform;
}

.project-section-title, .project-section-subtitle{
    overflow-y: hidden;
}


/* Grid styling */
.grid__wrapper{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-column-gap: 16px;
    counter-reset: item;
    padding-top: 8vh;
    font-family: Lato;
}

.grid-item{
    position: relative;
    /* counter-increment: item; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    text-wrap: nowrap;
    transition: opacity 0.75s ease;
    aspect-ratio: 3/2;
}

.img-test{
    object-fit: cover;
    width: 100%;

}

.grid-item.is-inview{
    opacity: 1;
}

.large{
    grid-column-end: span 2;
    grid-row-end: span 1;
    aspect-ratio: 32/16;
}

.big{
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.grid-item__title{
    font-family: var(--font-helv);
    font-style: 65 Medium;
    color: var(--kodak-color);
    text-transform: uppercase;
    display: block;
    font-size: 0.45rem;
    margin-bottom: 15px;
    width: 100%;
}

.kodak-text-tweaks{
    transform: scale(1.05,0.975);
    transform-origin: right;
    letter-spacing: 0.075vw;
    filter: blur(0.015vw);
}

.title-glow{
    text-shadow:0px 0px 0.05vw red, 0px 0px 0.05vw red;
    position: absolute; 
}

.gi-num{
    font-style: "65 Medium";
    font-family: --var(--font-helv);
    color: var(--kodak-brightest);
    font-weight: bold;
    transform: scale(1.25, 1.095);
    letter-spacing: 0px;
    position: absolute;
    left: 0;
    padding-left: 10px;
}

.grid-item__still-selector{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.large-3{
    grid-column-end: span 1;
    grid-row-start: span 1;
}

.grid-item__still-selector > span{
    border-radius: 10px;
    height: 5px;
    width: 5px;
    border: 1px solid var(--kodak-color);
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.65s;
}

.grid-item__still-selector > .selected{
    background: var(--kodak-color);
}

.title-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.title-center > div{
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    padding: 0 5.5% 0% 5.5%;
    width: 100%;
    justify-content: center;
}

.title-center> span{
    overflow:hidden;
}

.fbr-wrapper, .lower-social-wrap{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.lower-social-wrap > div{
    padding-right: 1rem;
    font-weight: 400;
}
.lower-social-wrap a{
    padding: 0.35rem;
}


.grid_item-visuals__wrapper{
    border-radius: 1.5px;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display:flex;
    flex-direction: row;
    justify-content: center;
    will-change: transform;
}

.gi-background{
    min-width: 100%;
}

.gi-background{
    position: absolute;
}

.gi-background, .grid-item__hover{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* padding-bottom: 62.6%; */
    /* margin-top: -62.6%; */
    width: 100%;
    height: 100%;
}

.grid-title{
    display: inline-block;
    overflow: hidden;
    background: -webkit-linear-gradient(left, var(--kodak-bright) , var(--kodak-dark)); 
    background: -o-linear-gradient(right, var(--kodak-bright) , var(--kodak-dark));
    background: -moz-linear-gradient(right, var(--kodak-bright) , var(--kodak-dark));
    background: linear-gradient(to right, var(--kodak-bright) , var(--kodak-dark)); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-wrap: nowrap;

}

.gi-main-title__wrapper{
}

.grid-title span{
    display: inline-block;
    white-space: nowrap;
}

.main-container{
    will-change: transform;
    -webkit-font-smoothing: antialiased;
    webkit-backface-visibility: hidden;
   -moz-backface-visibility: hidden;
   -ms-backface-visibility: hidden;
   backface-visibility: hidden;

   -webkit-perspective: 1000;
   -moz-perspective: 1000;
   -ms-perspective: 1000;
   perspective: 1000;
   -webkit-transform: translateZ(0);
   -moz-transform: translateZ(0);
   -ms-transform: translateZ(0);
   -o-transform: translateZ(0);
   transform: translateZ(0);

   background: #020202;
}


.grid-item__hover{
    position: absolute;
    top:0;
    left: 0;
    opacity: 0;
}

.man-underline{
    background: var(--kodak-color);
    height: 1px;
    transform-origin: left;
}
.homepage-wrapper-full{
    background: #020202;
}
.grid-item__thumb{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.grid-item__thumb .thumb-in{
    /* transform: translate(100%, 0%); */
    animation: thumb-in 1s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

.grid-item__thumb .thumb-out{
    /* transform: translate(100%, 0%); */
    animation: thumb-out 1.25s cubic-bezier(0.33, 1, 0.68, 1) forwards;
    transform-origin: right;
}

.large-3 > .grid-item{
    aspect-ratio: 4/2 !important;
}

.is-vertical .grid__wrapper{
    padding-top: 0 !important;
}

/* KEYFRAMES */

@keyframes thumb-in {
    from {transform: translate(100%, 0%) scale(1.25, 1);}
    to {transform: translate(0%, 0%) scale(1,1);}
  }

@keyframes thumb-out {
    from {
        transform: translate(0%, 0%) scale(1, 1);
    }
    to {transform: translate(-100%, 0%) scale(1.6, 1);}
}

/* FOOTER */
.homepage-footer__wrapper{
    width: 100%;
    background: #020202;
    color: var(--kodak-dark);
    font-size: calc(max((25 / var(--headerFontMultiplier))*1rem, 11.25px));
}


.homepage-footer{
    padding-top: 15vh;
    padding-bottom: 15vh;
}

.ftr-upper-tier, .ftr-lower-tier, .homepage-footer{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

}

.ftr-upper-tier{
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: center;

}

.ftr-lower-tier, .homepage-footer{
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -moz-box-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
}

.ftr-upper-tier > span{
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* # Large Tablet Portrait*/
@media screen and (max-width: 1180px) and (min-height: 1180px){
    .grid-item__title {
        margin-bottom: 6px;
        font-size: calc(max(0.55rem, 10px));
    }
    
    .lower-wrapper{
        font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 9px)) !important;
    }

    .homepage-footer__wrapper {
        font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 11.25px));
    }
}

/* # Large Tablet Landscape*/
@media screen and (max-width: 1180px) and (max-height: 1180px) {
    .grid-item__title {
        margin-bottom: 6px;
        font-size: calc(max(0.55rem, 10px));
      }
}

/* Small Tablet */
@media (max-width: 860px){
    .homepage-bottom-fixed{
        padding-bottom: 1.5rem;
    }
    .grid-item__title {
        margin-bottom: 6px;
        font-size: max(11px, .7rem);
      }
      .lower-social-wrap > div{
        padding-right: 2rem;
        font-weight: 400;
    }
    
}

/* Small Tablet */
@media (max-width: 700px){

    .grid-item__title {
        margin-bottom: 4px;
        font-size: calc(max(7px, 0.625rem));
    }
}