.secondary-cursor {
    z-index: 1000;
    pointer-events: none;
    // overflow: hidden;
    transform: translate3d(0, 0, 0);
    position: fixed;
    color: white;
    font-size: 30px;
    // border: 1px solid black;
    mix-blend-mode: difference;
    transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // &.regular {
    //   width: 40px;
    //   height: 40px;
    // }
    // &.small {
    //   width: 60px;
    //   height: 60px;
    // }
  }
  
  
  .secondary-cursor {
    z-index: 10000;
    font-size: var(--font-0);
    pointer-events: none;
    // overflow: hidden;
    transform: translate3d(0, 0, 0);
    transform-origin: center;
    position: fixed;
  }
  
  .slider-drag {
  
  
   
  }
  
  .hamburger {
    
  
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes bgUp {
    from {
      background-color: transparent;
    }
  
    to {
      background-color: #020202;
    }
  }
  
  @keyframes bgDown {
    from {
      background-color: #020202;
    }
  
    to {
      background-color: transparent;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
  
    to {
      transform: scale(1.5);
    }
  }
  
  @keyframes scaleDown {
    from {
      transform: scale(1.5);
    }
  
    to {
      transform: scale(1);
    }
  }
  
  @keyframes translateLeftDot {
    from {
      transform: translate(20px, -50%);
    }
  
    to {
      transform: translate(0px, -50%);
    }
  }
  
  @keyframes translateRightDot {
    from {
      transform: translate(-20px, -50%);
    }
  
    to {
      transform: translate(0px, -50%);
    }
  }
