.morph{
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    width: 110vw;
    height: 100vh;
    pointer-events: none;
    mix-blend-mode: screen; 
}

#custom-morph{
    animation: 0.75s forwards fade-out ease-out;
    animation-delay: 1.25s;
}

.menu-path-clipper{
    transform: scale(0.000520833333, 0.00092592592) rotateZ(0);
}

#mnu-overlay-path{
    transform: scale(0.000520833333, 0.00092592592) rotateZ(0);
}

#path-test{
    transform: scale(0.000520833333, 0.00092592592) rotateZ(0);
    z-index: 999999;
}