.index__wrapper{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12px;
    line-height: 1.85;
}

.index-tags__wrapper{}

.tag-list > li{

}


.index-item-1{

}

.index-item-2{

}

.index-item-3{

}

.index-cur-title{
    position: fixed;
    top: 50dvh;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: var(--title-4);
    color: white;
    z-index: 10;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 90vw;
}

.nav-list{
    pointer-events: none;
    -moz-columns: 3;
    column-count: 3;
    grid-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    transition: opacity .8s cubic-bezier(.165,.84,.44,1) .4s;
    display: list-item;
    text-align: -webkit-match-parent;
}

.works-li{
    text-transform: uppercase;
}


.tag-list{
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 17px;
    width: 100vw;
    align-items: start;
    text-align: center;
}

.tag-list > li{
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (hover: hover) {
    .tag-button:hover{
        /* background-color: var(--kodak-brightest);
        border-color: var(--kodak-brightest); */
        background-color:white;
        border-color: white;
        color: #020202;
        opacity: 1;
        cursor: pointer;
    }
    .works-option:hover .option-text{
        transform: translate(30px, 0px) !important;
        color: var(--kodak-brightest);
        z-index: 10;
        cursor: pointer;
    }
    .works-option:hover .option-decoration, .option-scrolled .option-decoration{
        opacity: 1;
        transform: translate(0%, 0%);
    }
}
.tag-option{
    text-align: center;
    pointer-events: cursor;
    display: inline-flex;
    flex-basis: 0;
}

.tag-button{
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 60px;
    border: 1px solid grey;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    flex-basis: 0;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    opacity: 0.65;
    transition: opacity 0.35s ease, color 0.35s ease, background-color 0.35s ease;
    white-space: nowrap;
}

.tag-button > span{
    text-wrap: nowrap;
    pointer-events: none;
}

.works-option{
    pointer-events: all;
    transition: opacity 0.4s ease;
    position: relative;
}

.option-scrolled .option-text {
    transform: translate(30px, 0px) !important;
    color: var(--kodak-brightest);
    z-index: 10;
    cursor: pointer;
}

.works-option .option-text{
    display: inline-block;
    transform: translate(0,0);
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .6s cubic-bezier(.165,.84,.44,1), color 0.5s;
}

.option-scrolled .option-decoration{
    opacity: 1;
    transform: translate(0%, 0%);
}

.option-decoration{
    position: absolute;
    opacity: 0;
    display: inline-block;
    transform: translate(-100%, 0%);
    transition: transform 0.35s ease, opacity 0.35s ease;
}

.work-unselected{
    opacity: 0.5;
}

.work-unselected{
    pointer-events: none;
}

.work-unselected .option-text{
    text-decoration: line-through;
}

del{
    position: absolute;
    height: 100%;
    left: 0%;
    top: 0%;
    width: 100%;
}
@keyframes slide-in {
    from {
        width: 0%;
    }
    to {
       width: 100%;
    }
}

@keyframes slide-out {
    from {
        width: 100%;
    }
    to {
       width: 0%;
    }
}

del .redacted {
    /* display: flex;
    flex-wrap: nowrap; */
    display: block;
    flex-shrink: 0;
    flex-basis: 0;
    background-color: #383838;
    color: transparent;
    /* -webkit-transform: rotate(0.5deg) skewx(-8deg);
    -moz-transform: rotate(0.5deg) skewx(-8deg);
    -o-transform: rotate(0.5deg) skewx(-8deg);
    -ms-transform: rotate(0.5deg) skewx(-8deg);
    transform: rotate(0.5deg) skewx(-8deg);
    padding: 0 1em;
    margin: 0 -0.5em; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    border-radius: 2px;
    pointer-events: none;
    /* position: absolute;
    left: 0;
    top:0;  */
    height: 100%;
    animation: slide-in forwards;
    animation-duration: 0.35s;
    animation-iteration-count: forwards !important;
    animation-timing-function: ease-in;
    transform-origin: left;
  }

  .redact-in{
    
  }

  .redact-out{
    position: absolute;
    left: auto !important;
    right: 0;
    animation: slide-out forwards !important;
    animation-duration: 0.35s !important;
    animation-iteration-count: forwards !important;
    animation-timing-function: ease-out !important;
    transform-origin: right !important;
  }

  del span:nth-child(1) {
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -o-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  del span:nth-child(2) {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
  del span:nth-child(5) {
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  
.tag-select{
    opacity: 1;
    background: white;
    color: #020202;
}

.index-item-top{
    top: 25%;
}

.index-item-bottom{
    bottom: 25%;
}

.index-item-left{
    left: 0;
}

.index-item-right{
    right: 0;
}

@media (min-width: 1921px){
    .index-item-top{
        top: 0%;
    }
    .index-item-left{
        left: 7.5%;
    }
    
    .index-item-right{
        right: 7.5%;
    }
    .index-item-bottom{
        bottom: 0%;
    }
    .viewport .scene3D-container .scene3D > div {
        width: 30% !important;
    }
}


@media (max-width: 1920px){
    .index-item-top{
        top: 7.5%;
    }
    
    .index-item-bottom{
        bottom: 7.5%;
    }
    .index-item-left{
        left: 7.5%;
    }
    
    .index-item-right{
        right: 7.5%;
    }

    .viewport .scene3D-container .scene3D > div {
        width: 30% !important;
    }
  }

/* # Large Tablet */
@media (max-width: 1440px){
    .index-item-top{
        top: 10.5%;
    }
    
    .index-item-bottom{
        bottom: 10.5%;
    }
    .index-item-left{
        left: 7.5%;
    }
    
    .index-item-right{
        right: 7.5%;
    }

    .viewport .scene3D-container .scene3D > div {
        width: 30% !important;
    }
  }
  
  /* Small Tablet */
  @media (max-width: 860px){
    .index-item-top{
        top: 15%;
    }
    
    .index-item-bottom{
        bottom: 15%;
    }
    .index-item-left{
        left: -20%;
    }
    
    .index-item-right{
        right: -20%;
    }

    .viewport .scene3D-container .scene3D > div {
        width: 65% !important;
    }
    :root {
        --scenePerspective: 0.45 !important;
    }
    .tag-option{
    padding: 10px
    }

    .tag-list>li{
    padding: 0;
    margin-right: 3px;
    }
    .index-cur-title{
        font-weight: 100;
    }
    .index__wrapper{
        top: 90%;
        transform: translate3d(-50%, -100%, 0);
        padding-bottom: 15px;
        font-size: 1.25rem;
    }
  }
  
  
  /* Small Tablet */
  @media (max-width: 700px){
    .index-item-top{
        top: 25%;
    }
    
    .index-item-bottom{
        bottom: 25%;
    }
    .index-item-left{
        left: -20%;
    }
    
    .index-item-right{
        right: -20%;
    }

    .viewport .scene3D-container .scene3D > div {
        width: 65% !important;
    }
    :root {
        --scenePerspective: 0.65 !important;
      }
      .tag-list>li{
        padding: 0;
        margin-right: 3px;
        margin-bottom: 0;
        width: 35%;
      }

      .index__wrapper{
        font-size: 1.4rem;
      }
    
  }

  /* percentage */
  .index-percentage__wrapper{
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background: rgb(50, 50, 50);
    margin-top: 20px;
  }

  #index-scroll-perc{
    background: var(--kodak-brightest);
    height: 3px;
    will-change: width;
    width: 0%;
  }

  .line-tickers > div{
    width: 100%;
    border-right: 1px solid black;
  }

  .scroll-signifier{
    position: fixed;
    top: 60%;
    left: 50%;
    font-size: 10px;
    transform: translateX(-50%);
    margin-top: 2rem;
    color: var(--color-highlighted-dark);
    animation: glow 2s ease infinite;
    overflow: hidden;
  }

  .tag-signifier__wrapper{
    position: relative;
  }

.tag-click-signifier svg line{
    stroke: var(--color-highlighted-dark);
}

.tag-click-signifier svg{
    animation: glow 2s ease infinite, arrow-bob-br 5s ease-out infinite;
    transform: translate(0px, 10px);
}

.tag-click-signifier span {
    position: absolute;
    transform: translate(-110%, -100%);
    white-space: nowrap;
    padding-bottom: 0.5rem;
    font-size: 9px;
    color: var(--color-highlighted-dark);
    animation: glow 2s ease infinite;
  }

  .scroll-signifier span{
    display: inline-block;
  }

  .mobile-scroll-signifier{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-highlighted-dark);
    animation: glow 3s ease infinite;
    font-size: 10px;
  }

  .select-signifier-mob{
    color: var(--color-highlighted-dark);
    animation: glow 3s ease infinite;
  }

  @keyframes glow{
    0%{
        color: var(--color-highlighted-dark);
    }
    50%{
        color: var(--color-highlighted);
    }
    100%{
        color: var(--color-highlighted-dark);
    }
  }

  @keyframes arrow-bob-br{
    0%{
        transform: translate(0px, 10px);
    }
    50%{
        transform: translate(-2px, 8px);
    }
    100%{
        transform: translate(0px, 10px);
    }
  }

  @media (max-width: 860px){
    .scroll-signifier{
      top: 65%;
    }
  }