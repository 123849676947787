.__1SGKOh{
    --main-bg: #efebe7;
    --accent-color: #efebe7;
    --dark-color: #080808;
    --gw-color: #7f7f7f;
    --gb-color: #7b7977;
    --dark-bg: #000;
    --gfs: 31;
    --default-duration: 0.8s;
    --default-timing-fc: ease;
    --cursor-color: #c0bbb6;
    --default-br: calc(10/var(--gfs)*1rem);
    --default-br-m: calc(15/var(--gfs)*1rem);
    --vh: 5.67px;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-family: Monument Grotesk,Arial,Helvetica,sans-serif;
    font-size: calc((18/var(--gfs))*1rem);
    line-height: calc((22/var(--gfs))*1rem);
    --global-bg: #050505;
    --cc: var(--accent-color);
    --g-color: var(--gb-color);
    --fc: grayscale(1);
    color: var(--accent-color);
    fill: transparent;
    cx: 346.5;
    cy: 345.5;
    r: 156;
    stroke-width: 312;
    box-sizing: inherit;
    stroke-dasharray: 990;
    opacity: .3;
    stroke: white;
    stroke-dashoffset: 0;
}

.timer__wrapper{
    position: absolute;
    height: 50%;
    width: 50%;
    overflow: visible;
}

.timer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.timer__content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.timer__content > span{
    font-size: 8rem;
}

.a404__footer{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    font-size: calc(max((22 / var(--headerFontMultiplier))*1rem, 9.25px));
    text-transform: uppercase;
    padding-bottom: 20px;
}

.a404__wrapper{
    
}

.redirect__wrapper{
    animation: breathe 3s ease infinite;
}
.test:before {
    animation: dots 2s linear infinite;
    content: '';
  }

  @keyframes dots {
    0%, 20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%, 100% {
      content: '';
    }
}


@keyframes breathe{
    0% {
        opacity: 100%
    }
    50%{
        opacity: 20%;
    }
    100%{
        opacity: 100%;
    }
}