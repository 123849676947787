.menu-wrapper, .menu-wrapper2, .menu-wrapper3{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: opacity 350ms ease-out forwards;

}

.menu-wrapper3{
    background: rgb(247, 245, 242);
    clip-path: url(#menuOverlay3);
    z-index: 9;
    mix-blend-mode: difference;
}

.menu-wrapper2{
    background: rgb(235, 227, 212);
    clip-path: url(#menuOverlay2);
    z-index: 10;
}

.menu-wrapper{
    background: #020202;
    clip-path: url(#menuOverlay);
    z-index: 11;
}

.menu-content{
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.menu-content > * {
    z-index: 11;
    color: white;
}


.menu-left-v-split, .menu-right-v-split{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-right-v-split{
    align-items: flex-end;
}

.menu-h-split{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu-name-statement__wrapper{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    /* RESPONSIVE NEED TO CHANGE */
    font-size: 8rem;
    /* RESPONSIVE NEED TO CHANGE */
    letter-spacing: 2rem;   
}

.menu-options__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* RESPONSIVE CHANGE NEEDED */
    font-size: 60px; 
}

.menu-option-title{
    overflow: hidden;
    font-family: "Cormorant Garamond";
    font-style: italic;
}

.menu-option-active{
    color: var(--color-highlighted)
}