.main__preloader__wrapper{position:fixed;top:0;left:0;height:100%;width:100%;background:#141414;animation:toBlack 1.5s 1s ease forwards;z-index:9999;text-transform:uppercase;color:#fff;scroll-behavior:unset;overflow:hidden;clip-path:url(#loaderOverlay)}
.info__wrapper{height:100%;width:100%;will-change:filter;filter:blur(3px);animation:blurToFocus 1.5s 1s ease-in forwards;display:flex;flex-direction:column;justify-content:space-between;align-items:center}
@keyframes toBlack{
100%{background:#000}
}
.info__wrapper>div>div,.preload__lower__wrapper,.preload__upper__wrapper{display:flex;flex-direction:row;justify-content:space-between;width:100%;mix-blend-mode:difference}
.up_center{position:absolute;display:flex;flex-direction:row;justify-content:flex-start;left:50%;transform:translate(-50%,0);font-size:var(--font-0)}
.focus_content__wrapper{display:flex;flex-direction:column}
.focus_ring__wrapper{position:absolute;display:flex;flex-direction:row;align-items:space-between;left:50%;transform:translate(-50%,0);overflow:hidden;padding-bottom:15px}
.focus__unit{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-end;padding-right:20px}
.up_right{display:flex;flex-direction:row;justify-content:space-between}
#preload__center{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);justify-content:center;align-items:center;mix-blend-mode:normal}
.preload__upper .up_left{display:flex;flex-direction:row;justify-content:space-between}
.cam_opt{display:flex;flex-direction:row;padding-right:.5rem}
.preload__lower .low_left{display:flex;flex-direction:row;justify-content:flex-end;align-items:flex-end}
.preload__upper .up_left .div:last-child{text-transform:uppercase}
#preload__center>picture{position:relative;border-radius:calc((10/var(31))*1rem);overflow:hidden;width:100%;height:100%}
.pl_thumb__wrap{width:400px;height:327px;background:#fff;background-size:contain}
picture>img{position:absolute;top:0;left:0;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:top center;object-position:top center;filter:grayscale(100%)}
.focus_ring{display:flex;position:relative;transform:translateX(-100%);flex-direction:row;justify-content:flex-start;will-change:transform;animation:rightToLeft 2.15s .35s cubic-bezier(.33,1,.68,1) forwards}
@keyframes rightToLeft{
0%{transform:translateX(-100%)}
100%{transform:translateX(0)}
}
@keyframes blurToFocus{
0%{filter:blur(3px)}
100%{filter:blur(0)}
}
.dist__measure{display:flex;flex-direction:column;align-items:flex-start;padding:0 5px}
.dist__measure span:first-child,.focus__unit>div:first-child{mix-blend-mode:revert!important;color:#c69d05}
.foc_ring_half_pos{padding-left:10px}
.low_center{position:absolute;left:50%;bottom:0;transform:translateX(-50%);display:flex;flex-direction:column;justify-content:center;text-align:center}
.local-roll__wrapper{position:absolute;right:0;transform:translate3d(0,0,0)}
@keyframes testFade{
0%{opacity:0}
100%{opacity:1}
}
@keyframes textScroll{
0%{transform:translate3d(0,0,0)}
100%{transform:translate3d(0,-100%,0)}
}
.location-scroll__fade-wrap{position:absolute;bottom:0;display:flex;flex-direction:column;width:100%;opacity:0;animation:testFade 2s 1s ease forwards;background:#000;background:linear-gradient(0deg,rgba(0,0,0,.9542410714285714) 0,rgba(0,0,0,.5620842086834734) 25%,rgba(0,0,0,0) 50%,rgba(0,0,0,.5592830882352942) 75%,rgba(0,0,0,.9486388305322129) 100%)}
.low_right{position:relative;overflow:hidden}
.lower-shutter,.upper-shutter{position:absolute;width:100%;background:#000;z-index:9}
.upper-shutter{top:0;transform:translate3d(0,-100%,0);will-change:transform;animation:topToBottom 1.5s 3s cubic-bezier(.33,1,.68,1) forwards}
.lower-shutter{bottom:0;transform:translate3d(0,100%,0);animation:bottomToTop 1.5s 3s cubic-bezier(.33,1,.68,1) forwards}
@keyframes bottomToTop{
0%{transform:translate3d(0,100%,0)}
100%{transform:translate3d(0,0,0)}
}
@keyframes topToBottom{
0%{transform:translate3d(0,-100%,0)}
100%{transform:translate3d(0,0,0)}
}
.ov-hidden{overflow:hidden}
.inl-block{display:inline-block}
@media (min-width:861px){
.low-right,.main__preloader__wrapper{font-size:calc(min((20 / var(--headerFontMultiplier))*1rem,16px))}
}
@media (max-width:860px) and (min-width:700px){
.low_right,.main__preloader__wrapper{font-size:calc(max((27 / var(--headerFontMultiplier))*1rem,9px))}
}
@media (min-width:701px) and (max-width:1180px){
#preload__center{width:calc((660/31)*1rem);height:calc((539/31)*1rem)}
}
@media (min-width:1180px){
#preload__center{width:calc((500/31)*1rem);height:calc((408/31)*1rem)}
}
@media (max-width:700px){
.low_left{display:flex;flex-direction:column!important;justify-content:center!important}
.low_right,.main__preloader__wrapper{font-size:calc(max((35 / var(--headerFontMultiplier))*1rem,9px))}
#preload__center{width:calc((500/20)*1rem)!important;height:calc((408/20)*1rem)!important}
.up_left{flex-direction:column!important}
.info__wrapper{filter:unset!important;animation:none!important}
}