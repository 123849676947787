.navbar-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    font-family: var(--font-punchy);
    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    pointer-events: none;

}

.navbar-wrapper > div {
    pointer-events: all;
}

#menu-btn__wrapper{
    /* display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: end;
    overflow: hidden;
    padding: 2rem; */
}

.menu-option-wrapper{
    z-index: 10;
    overflow-y: hidden;
    text-decoration: underline;
    position: relative;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: end;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 20px;
    
}

.menu-option{
    overflow: hidden;
    transition: border-bottom 1s ease;
    border-bottom: 1px solid black;
}

.index-wrapper{
    padding-left: 0px !important;
}

.upper-wrapper{
    font-weight: 500;
    letter-spacing: 1px;
}

.lower-wrapper{
    font-weight: 400;
}

.upper-wrapper, .lower-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: calc(max((25 / var(--headerFontMultiplier))*0.9rem, 9px));
    
    position: relative;
}

.local-wrapper{
    position:absolute;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: row;
    transition: opacity 0.6s ease forwards;
}

.local-code{
    padding-right: 8px;
}

.menu-right{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.call-sign{
    font-size: min(max(0.8rem, 11px), 20px);
    font-weight: 500;
    letter-spacing: 4px;
    white-space: nowrap;
    will-change: padding-top;
    transition: padding-top 0.75s ease;
    font-family: var(--font-helv);
}

.expand{
    padding-top: 1.25rem;
}

.call-sign__char{
    display: inline-block;
}

.underline-forced{
    border-bottom: 1px solid white;
    transition: border-bottom 1s ease;
}

.underline-anim-out{
    animation: bottomBorderFadeOut 300ms ease forwards;
}

@media (max-width: 1450px){
    .upper-wrapper, .lower-wrapper, .shoot-footer, .information-page__footer{
        font-size: calc(max((27 / var(--headerFontMultiplier))*0.9rem, 9px));
    }
}

@media screen and (max-width: 1180px) and (min-height: 1180px){
    .upper-wrapper, .call-sign, .shoot-footer, .information-page__footer{
      font-weight: 500 !important;
      font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 9px));
    }
}

@media screen and (max-width: 1180px) and (max-height: 1180px) {
    .upper-wrapper, .lower-wrapper, .call-sign, .shoot-footer, .information-page__footer{
      font-weight: 500;
      font-size: calc(max((30 / var(--headerFontMultiplier))*1rem, 9px));
    }
}

/* Small Tablet */
@media (max-width: 860px){
    .upper-wrapper, .lower-wrapper, .shoot-footer, .information-page__footer{
        font-weight: 500 !important;
    }
}

/* Small Tablet */
@media (max-width: 700px){
    .upper-wrapper, .lower-wrapper, .call-sign, .shoot-footer, .information-page__footer{
        font-size: calc(max((32 / var(--headerFontMultiplier))*1rem, 12px));
        font-weight: 500 !important;
    }
    .shoot-footer{
        font-size: calc(max((32 / var(--headerFontMultiplier))*1rem, 12px));
    }
}

@keyframes bottomBorderFadeOut {
    0% {
      border-bottom: 1px solid rgba(255,255,255,1) !important;
    }
  
    100% {
        border-bottom: 1px solid rgba(255,255,255,0) !important;
    }
  }